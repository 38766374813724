import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import EditIcon from "@material-ui/icons/Edit";
import get from "lodash/get";

import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "react-table/react-table.css";
import {
  EQUIPMENT_DETAIL_PROPERTY_WIDTH,
  PLACEHOLDER_IMAGE,
  THUMBNAIL_SIZE,
  EDIT_ITEM,
} from "../../../Constants";
import ResponsiveDrawer from "../../../fhg/components/ResponsiveDrawer";
import Typography from "../../../fhg/components/Typography";
import { getProfileThumbnail } from "../../../fhg/utils/Utils";
import { getEquipmentDescription } from "../EquipmentUtil";
import EquipmentListDetailEstimate from "../list/EquipmentListDetailEstimate";
import AssetDetail from "./AssetDetail";
import eventCapture from "../../../eventList";
import { posthogCapture } from "../../../PostHogFunction";
import { reSumbmitItemToIms } from "../../../Utils/SubmitUtil";
import { submitFormToIms } from "../../../Utils/SubmitUtil";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import IMSformCategtorySelect from "../list/IMSformCategtorySelect";
import { IMSContext } from "../list/IMSformCategtorySelect";
import { useContext, createContext } from "react";
import Divider from "@mui/material/Divider";
import SendIcon from "@mui/icons-material/Send";
import RequiredFieldsAlert from "./RequiredFieldsAlert";
import useEvaluationData from "../dashboard/eval_components/fetchEvalItemhook";

const useStyles = makeStyles(
  (theme) => ({
    backgroundStyle: {
      backgroundColor: theme.palette.environment.light.level0.base,
      padding: theme.spacing(0, 3, 2),
      overflow: "auto",
      height: "100%",
    },

    submit: {},

    backgroundStyleIMSForm: {
      backgroundColor: theme.palette.environment.light.level0.base,
      padding: theme.spacing(2, 3, 2),
      overflow: "auto",
      height: "100%",
      width: "150%",
    },
    buttonStyle: {
      marginTop: theme.spacing(2),
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    titleStyle: {
      marginTop: theme.spacing(2),
      fontWeight: "bold",
      color: theme.palette.primary.main,
      textAlign: "center",
    },

    drawer: {
      height: "100%",
    },
    drawerPaper: {
      maxWidth: "100%",
      height: "100%",
      [theme.breakpoints.down(475)]: {
        width: "100%",
      },
    },
    imageFrame: {
      paddingTop: theme.spacing(1),
    },
    image: {
      maxHeight: THUMBNAIL_SIZE + theme.spacing(1) + 2,
      maxWidth: THUMBNAIL_SIZE + theme.spacing(1) + 2,
      border: `1px solid ${theme.palette.divider} !important`,
      padding: `${theme.spacing(0.5)}px !important`,
      position: "relative",
    },
    indicatorStyle: {
      backgroundColor: theme.palette.button.standard.secondary,
    },
    spinnerMargin: {
      marginLeft: theme.spacing(0.5),
    },
    tabsStyle: {
      backgroundColor: theme.palette.environment.light.level1.base,
      borderBottom: `1px solid ${theme.palette.environment.light.level1.accent}`,
    },
    descriptionStyle: {
      marginLeft: `${theme.spacing(1)}px !important`,
    },
  }),
  { name: "AssetPropertiesDrawer" }
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

AssetPropertiesDrawer.propTypes = {
  evalItem: PropTypes.object,
  evaluation: PropTypes.object,
  isOpen: PropTypes.bool,
  showThumbnail: PropTypes.bool,
  showEquipmentTitle: PropTypes.bool,
  width: PropTypes.number,
  breakpoint: PropTypes.string,
  onClose: PropTypes.func,
  onSaving: PropTypes.func,
};

export const IsTabClickedContext = createContext();

/**
 * Drawer for displaying and editing asset properties.
 */
function AssetPropertiesDrawer({
  evalItem = { evalItem },
  evaluation,
  isOpen,
  showThumbnail = true,
  showEquipmentTitle = true,
  width = EQUIPMENT_DETAIL_PROPERTY_WIDTH,
  breakpoint = "xs",
  onClose,
  onSaving,
}) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { isZoomed, isEdit, isNew } = get(location, "state", {});
  const [imsFormSent, setImsFormSent] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [errorAlertVisible, setErrorAlertVisible] = useState(false);
  const [successFormSub, setSuccessFormSub] = useState(false);
  const [errorFormSub, setErrorsFormSub] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isIMSFieldRequied, setIsIMSFieldRequied] = useState(false);
  const [openRequiredModel, setOpenRequiredModel] = useState(false);
  const { evaluationData, isLoading, error, tokenRefreshError } =
    useEvaluationData(evalItem.item_id);

  const {
    isTabClicked,
    setIsTabClicked,
    saveForm,
    setSaveForm,
    loadForm,
    setLoadForm,
  } = useContext(IsTabClickedContext);
  const customerNumber = get(evaluation, "evaluation.owner_id", "");

  const image = useMemo(
    () => showThumbnail && getProfileThumbnail(evalItem),
    [showThumbnail, evalItem]
  );

  const { selectedCategory } = useContext(IMSContext);

  //console.log("DRAWER CAT", selectedCategory);

  /**
   * Handle the changes to the selected tab.
   * @param event The event that selected a new tab.
   * @param selectedTabIndex The selectedTabIndex of the selected tab.
   */
  const handleChange = (event, selectedTabIndex) => {
    setSelectedTabIndex(selectedTabIndex);
  };

  const handleSumbmitAssetToIMS = async () => {
    posthogCapture(eventCapture.RESUBMIT_ASSET);
    setIsExporting(true);
    const itemId = get(evalItem, "item_id", get(evalItem, "PK"));

    try {
      await reSumbmitItemToIms(itemId);
      setSuccessAlertVisible(true);
    } catch (e) {
      setErrorAlertVisible(true);
    } finally {
      setIsExporting(false);
    }

    //  window.location.reload()
    //console.log("ITEMS ======================================>", itemId);
  };

  // const handleSumbmitFormToIMS = async () => {
  //   console.log("Form submission started");
  //   console.log(
  //     "Eval Data for item :",
  //     evaluationData,
  //     evaluationData?.item?.sticker_id,
  //     evaluationData?.item?.ims_forms_data?.jobcode,
  //     evaluationData?.item?.ims_forms_data?.nomination
  //   );

  //   //Check if any required fields are missing
  //   const hasAllRequiredFields =
  //     evaluationData?.item?.sticker_id &&
  //     evaluationData?.item?.ims_forms_data?.jobcode &&
  //     evaluationData?.item?.ims_forms_data?.nomination;

  //   if (!hasAllRequiredFields) {
  //     console.log("REQUIRES VALUES");
  //     setIsIMSFieldRequied(true);
  //     setOpenRequiredModel(true);
  //   } else {
  //     setIsExporting(true);
  //     const itemId = get(evalItem, "item_id", get(evalItem, "PK"));
  //     try {
  //       await submitFormToIms(itemId);
  //       handleSucessFormSubSnackbar(true);
  //       console.log("Form submission successful"); // Add this line
  //       window.location.reload();
  //     } catch (e) {
  //       handleErrorFormSubSnackbar(true);
  //       console.log("Form submission failed", e); // Add this line
  //     } finally {
  //       setIsExporting(false);
  //     }
  //   }
  // };

  const handleSumbmitFormToIMS = async () => {
    setIsExporting(true);
      const itemId = get(evalItem, "item_id", get(evalItem, "PK"));
      try {
        await submitFormToIms(itemId);
        handleSucessFormSubSnackbar(true);
        console.log("Form submission successful"); // Add this line
        window.location.reload();
      } catch (e) {
        handleErrorFormSubSnackbar(true);
        console.log("Form submission failed", e); // Add this line
      } finally {
        setIsExporting(false);
      }
  };
  
  const handleSucessFormSubSnackbar = (e) => {
    setSuccessFormSub(e);
  };

  const handleErrorFormSubSnackbar = (e) => {
    setErrorsFormSub(e);
  };

  const handleSucessFormSubSnackbarClose = () => {
    setErrorsFormSub(false);
    setSuccessFormSub(false);
  };

  const handleLoadForm = () => {
    setLoadForm(true);
    //  console.log("TEST LOAD BUTTONS=================+++++++>>>>", loadForm);
  };

  const handleSaveForm = () => {
    setSaveForm(true);
    //  console.log("TEST SAVE BUTTONS=================+++++++>>>>", saveForm);
  };

  const onEdit = () => {
    //window?.posthog?.capture(eventCapture.EDIT_ITEM);
    posthogCapture(eventCapture.EDIT_ITEM);

    history.push(EDIT_ITEM.format({ itemId: get(evalItem, "item.item_id") }), {
      ...location.state,
      isEdit: true,
      backPath: location.pathname,
    });
  };

  return (
    <ResponsiveDrawer
      isTemporary={isZoomed || isEdit || isNew}
      breakpoint={breakpoint}
      isOpen={isOpen && !isEdit && !isNew}
      sizeWidth={width.toString()}
      onClose={onClose}
      classes={{ drawer: classes.drawer, drawerPaper: classes.drawerPaper }}
    >
      <Tabs
        className={classes.tabsStyle}
        value={selectedTabIndex}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="off"
        classes={{ indicator: classes.indicatorStyle }}
      >
        <Tab
          label={
            <Typography
              className={"secondary-minimal-tab-small"}
              id={"equipmentList.equipment.label"}
            />
          }
          onClick={() => setIsTabClicked(false)}
        />
        <Tab
          label={
            <Typography
              className={"secondary-minimal-tab-small"}
              id={"equipmentList.evaluation.label"}
            />
          }
          onClick={() => setIsTabClicked(false)}
        />
        <Tab
          label={
            <Typography
              className={"secondary-minimal-tab-small"}
              id={"equipmentList.IMSform.label"}
            />
          }
          onClick={() => setIsTabClicked(true)}
        />
      </Tabs>
      {selectedTabIndex === 0 && (
        <Grid
          container
          className={classes.backgroundStyle}
          wrap={"nowrap"}
          direction={"column"}
        >
          <Grid
            container
            direction={"row"}
            wrap={"nowrap"}
            justifyContent={"space-between"}
            style={{ flex: "0 0 auto", marginTop: 8 }}
          >
            <Button
              className={"secondary-minimal-button-small no-print"}
              variant={"text"}
              style={{ width: 110, height: 36, marginLeft: -8 }}
              onClick={onEdit}
              disableRipple={true}
              disabled={
                !evaluation ||
                evaluation.item_count <= 0 ||
                get(evalItem, "item.item_id") === undefined
              }
            >
              <EditIcon style={{ marginRight: 4, fontSize: 18 }} />
              <Typography
                color="inherit"
                variant="button"
                id={"equipmentDetail.editAsset.button"}
              />
            </Button>
          </Grid>
          <Grid
            container
            direction={"row"}
            wrap={"nowrap"}
            justifyContent={"space-between"}
            style={{ flex: "0 0 auto", marginTop: 8 }}
          >
            <Button
              variant={"text"}
              className={"secondary-button"}
              onClick={handleSumbmitAssetToIMS}
              style={{ height: 33, minWidth: 180 }}
            >
              <Typography
                color="inherit"
                variant="button"
                id={"equipmentDetail.resendAssetToIMS.button"}
              />
            </Button>
          </Grid>
          {showThumbnail && (
            <Grid
              container
              className={classes.imageFrame}
              direction={"row"}
              wrap={"nowrap"}
            >
              <img
                src={image}
                alt=""
                className={classes.image}
                onError={(e) => {
                  e.target.src = PLACEHOLDER_IMAGE;
                }}
              />
              <Grid
                container
                item
                direction={"column"}
                className={classes.descriptionStyle}
                spacing={1}
              >
                <Grid item>
                  <Typography className={"level-3-heading"}>
                    {getEquipmentDescription(get(evalItem, "item"), {})}
                  </Typography>
                </Grid>
                <Grid item className={classes.location}>
                  <Typography className={"subtle-text-small"} values={evalItem}>
                    {get(evalItem, "item.location")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          <div>
            {/* Show the Snackbar for success */}
            <Snackbar
              open={successAlertVisible}
              autoHideDuration={10000}
              onClose={() => setSuccessAlertVisible(false)}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert severity="success" sx={{ width: "100%" }}>
                Asset Successfully Resubmited to IMS!
              </Alert>
            </Snackbar>
            {/* Show the Snackbar for error */}
            <Snackbar
              open={errorAlertVisible}
              autoHideDuration={10000}
              onClose={() => setErrorAlertVisible(false)}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert severity="error" sx={{ width: "100%" }}>
                Asset Resubmition Failed, kindly check your internet or refresh
                the page!
              </Alert>
            </Snackbar>
            {isExporting && (
              <CircularProgress
                className={classes.spinnerMargin}
                size={50}
                thickness={5}
              />
            )}
            {/* {isExporting && (
              <CircularProgress
                className={classes.spinnerMargin}
                size={50}
                thickness={5}
              />
            )} */}
          </div>
          <AssetDetail
            key={`estimates-${evalItem.item_id}`}
            evalItem={evalItem}
            showEquipmentTitle={showEquipmentTitle}
            width={width}
            onClose={isOpen && onClose}
            showLocation={false}
          />
        </Grid>
      )}
      {selectedTabIndex === 1 && (
        <EquipmentListDetailEstimate
          key={`estimates-${evalItem.item_id}`}
          evalItem={evalItem}
          evaluation={evaluation}
          showSaved
          onRefresh={onSaving}
        />
      )}
      {selectedTabIndex === 2 && (
        <Grid
          container
          className={classes.backgroundStyleIMSForm}
          wrap={"nowrap"}
          direction={"column"}
        >
          <Snackbar
            open={successFormSub} // Use the state variable here
            autoHideDuration={6000}
            onClose={handleSucessFormSubSnackbarClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleSucessFormSubSnackbarClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Form Sent to IMS Successfully!
            </Alert>
          </Snackbar>

          <Snackbar
            open={errorFormSub} // Use the state variable here
            autoHideDuration={6000}
            onClose={handleSucessFormSubSnackbarClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleSucessFormSubSnackbarClose}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Error Sending Form to IMS!
            </Alert>
          </Snackbar>
          <IMSformCategtorySelect
            customerNumber={customerNumber}
            evalItem={evalItem}
          />

          <div style={{ margin: "12px 0" }}></div>
          <Grid>
            {/* <Button
              variant={"text"}
              className={"third-button"}
              onClick={handleLoadForm}
              style={{ height: 33, minWidth: 180, marginRight: "18px" }}
            >
              <Typography
                color="inherit"
                variant="button"
                id={"equipmentList.IMSformLoadButton.label"}
              />
            </Button> */}

            <Button
              variant={"text"}
              className={"third-button"}
              onClick={handleSaveForm}
              style={{ height:40, width: "60%" }}
            >
              <Typography
                color="inherit"
                variant="button"
                id={"equipmentList.IMSformSaveButton.label"}
              />
            </Button>
            <Divider style={{ marginTop: "20px", maxWidth: "60.5%" }} />
            <Grid style={{ marginTop: "25px" }}>
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  width: "60.5%",
                }}
              >
                {/* {isIMSFieldRequied && (
                  <RequiredFieldsAlert
                    imsFormData={{
                      "Sticker ID": evaluationData?.item?.sticker_id,
                      "Job Code": evaluationData?.item?.ims_forms_data?.jobcode,
                      "Nomination": evaluationData?.item?.ims_forms_data?.nomination,
                    }}
                    onOpen={openRequiredModel}
                    onClose={() => setOpenRequiredModel(false)}
                  />
                )} */}
                {evalItem?.item?.form_sub ? (
                  <Button
                    variant="contained"
                    endIcon={<SendIcon sx={{ marginLeft: 2 }} />}
                    onClick={handleSumbmitFormToIMS}
                    className="fourth-button"
                    style={{
                      height: 40,
                      width: "100%",
                    }}
                  >
                    <Typography
                      color="inherit"
                      variant="button"
                      id={"equipmentList.IMSforResubmitButton.label"}
                    />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    endIcon={<SendIcon sx={{ marginLeft: 2 }} />}
                    onClick={handleSumbmitFormToIMS}
                    className="fourth-button"
                    style={{
                      height: 40,
                      width: "100%",
                    }}
                  >
                    <Typography
                      color="inherit"
                      variant="button"
                      id={"equipmentList.IMSformSubmitButton.label"}
                    />
                  </Button>
                )}

                {isExporting && (
                  <div
                    style={{
                      position: "absolute",
                      top: "calc(100% + 30px)", // Position below the button with a 10px gap
                      left: "50%",
                      transform: "translateX(-50%)", // Center horizontally
                    }}
                  >
                    <CircularProgress size={50} thickness={5} />
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}
    </ResponsiveDrawer>
  );
}

export const IsTabClickedProvider = ({ children }) => {
  const [isTabClicked, setIsTabClicked] = useState(false); // Default value is false
  const [saveForm, setSaveForm] = useState(false);
  const [loadForm, setLoadForm] = useState(false);

  return (
    <IsTabClickedContext.Provider
      value={{
        isTabClicked,
        setIsTabClicked,
        saveForm,
        setSaveForm,
        loadForm,
        setLoadForm,
      }}
    >
      {children}
    </IsTabClickedContext.Provider>
  );
};

export default AssetPropertiesDrawer;
