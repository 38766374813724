import { Hidden } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import classNames from "classnames";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { PureComponent, Fragment } from "react";
import ReactTableOriginal from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import Typography from "../../../fhg/components/Typography";
import Feature from "../../../fhg/security/Feature";
import NotFeature from "../../../fhg/security/NotFeature";
import { SUB_PACKAGES } from "../../../Utils/ServerUtil";
import SubPackageFilterMenu from "../components/SubPackageFilterMenu";
import SubpackagesPopover from "../components/SubpackagesPopover";
import { PackageTotal } from "./EquipmentList";
import { GoShareAndroid } from "react-icons/go";
import ShareModal from "./ShareModal";
import Modal from "@mui/material/Modal";
import Paper from "@material-ui/core/Paper";
import { Oval } from "react-loader-spinner";
import { SHARE_SUCCESS } from "../../../Constants";

const MAP_SORT_TO_LABEL = {
  created: "equipmentListSort.created.label",
  updated: "equipmentListSort.updated.label",
  location: "equipmentListSort.location.label",
  make: "equipmentListSort.make.label",
  model: "equipmentListSort.model.label",
  serial: "equipmentListSort.serial.label",
  vin: "equipmentListSort.vin.label",
  year: "equipmentListSort.year.label",
  inventoryId: "equipmentListSort.inventoryId.label",
  stickerId: "equipmentListSort.stickerId.label",
  miles: "equipmentListSort.miles.label",
  hours: "equipmentListSort.hours.label",
};

// withFixedColumns doesn't handle ref and/or innerRef correctly. Use tableRef as the ref and innerRef.
function Table({ tableRef, ...props }) {
  return <ReactTableOriginal ref={tableRef} innerRef={tableRef} {...props} />;
}

Table.propTypes = {
  ...ReactTableOriginal.propTypes,
};
Table.defaultProps = {
  ...ReactTableOriginal.defaultProps,
};
const ReactTable = withFixedColumns(Table);

const styles = (theme) => ({
  equipmentHeaderStyle: {
    maxHeight: "100%",
    border: "none",
    paddingLeft: 2,
    overflow: "hidden",
    "@media print": {
      display: "table",
    },
    "& .rt-tbody": {
      height: 0,
    },
    "& .rt-th div": {},
    "& .rt-tr": {
      marginLeft: "4px !important",
      marginTop: 1,
      marginBottom: 1,
      flex: "0 0 auto",
    },
    "& .rt-tbody .rt-tr": {
      // Needed to make the :after 'absolute' position work for the selection borders.
      position: "relative",
    },
    "& .rt-tbody .rt-tr-group": {
      borderBottom: `1px solid ${theme.palette.environment.light.level0.accent} !important;`,
    },
    "& .rt-resizable-header-content": {
      height: "100%",
      paddingBottom: theme.spacing(1),
    },
  },
  titleStyle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleFrame: {
    height: "auto !important",
    marginLeft: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(0.5),
    },
  },
  buttonFrame: {
    height: "auto !important",
    marginLeft: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  paper: {
    padding: theme.spacing(1),
    // margin: theme.spacing(-1, 0, 0, -1),
  },
  defaultTextStyle: {
    color: theme.palette.text.secondary, //'rgba(66,51,19,0.8)',
  },
  menuText: {
    color: theme.palette.text.secondary,
    display: "inline-block",
  },
  buttonRight: {
    marginLeft: "auto",
  },
  headerContent: {
    display: "flex",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "auto",
  },
  shareIcon: {
    fontSize: "5rem",
    marginLeft: theme.spacing(1), // Add margin to create space between the text and icon
  },
  modal2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper2: {
    outline: "none",
    position: "relative",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  squareImage: {
    width: "80%", // Adjust the width of the image
    height: "auto",
    display: "block",
    margin: "0 auto", // Center the image horizontally
  },
  textContainer: {
    textAlign: "center",
  },
  successText: {
    fontSize: "24px", // Adjust the font size of the text
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
});

class AssetTableHeader extends PureComponent {
  static propTypes = {
    classes: PropTypes.any,
    saving: PropTypes.any,
    evaluation: PropTypes.object,
    total: PropTypes.number,
    estimateTotals: PropTypes.any,
    onAdd: PropTypes.func.isRequired,
    onShowNotes: PropTypes.func.isRequired,
    onShowSubpackages: PropTypes.func.isRequired,
    onSort: PropTypes.func.isRequired,
    sortBy: PropTypes.oneOfType([
      PropTypes.string, // Accepts a string type as well
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          desc: PropTypes.bool.isRequired,
        })
      ),
    ]),
    isWideView: PropTypes.bool,
    isNarrowView: PropTypes.bool,
  };

  static defaultProps = {
    sortBy: "created",
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      anchorEl: undefined,
      subpackageTotals: undefined,
      sort: get(props, "sortBy[0].id"),
      showShare: false,
      sucessOpen: false,
      showLoading: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.sortBy !== this.props.sortBy) {
      this.setState({ sort: get(this.props, "sortBy[0].id") });
    }
  }

  /**
   * Show the subpackage totals for the catalog.
   *
   * @param event The event that triggers the show collaborators.
   */
  handleShowSubpackages = (event) => {
    const subpackageTotals = this.props.onShowSubpackages();
    this.setState({ subpackageTotals, anchorEl: event.currentTarget });
  };

  /**
   * Close the subpackage totals popover.
   */
  handlePopoverClose = () => {
    this.setState({ subpackageTotals: undefined, anchorEl: undefined });
  };

  /**
   * Handle changes to the sort.
   * @param event The change event.
   */
  handleChange = ({ target }) => {
    setTimeout(() => {
      this.setState({ [target.name]: target.value }, () => {
        this.props.onSort && this.props.onSort(target.value);
      });
    }, 1);
  };

  handleSucessModal = () => {
    const { classes } = this.props;
    return (
      <Modal
        className={classes.modal2}
        open={this.state.sucessOpen}
        onClose={() => this.setState({ sucessOpen: false })}
        closeAfterTransition
      >
        <Paper className={classes.paper2}>
          <div>
            <img
              src={SHARE_SUCCESS}
              alt="Shared Successfully"
              className={classes.squareImage}
            />
          </div>
          <div className={classes.textContainer}>
            <Typography className={classes.successText}>
              Shared successfully
            </Typography>
          </div>
        </Paper>
      </Modal>
    );
  };

  loaderComp = () => {
    return (
      <div
        style={{
          position: "fixed", // Set the position to fixed to float on top of other components
          top: "50%", // Position it at the vertical center
          left: "50%", // Position it at the horizontal center
          transform: "translate(-50%, -50%)", // Center it precisely
          zIndex: 9999, // Set a high z-index to ensure it's above other content
        }}
      >
        <Oval
          visible={true}
          height="80"
          width="80"
          color="#4f2682"
          secondaryColor="ede8f3"
          ariaLabel="oval-loading"
          wrapperStyle={{}} // Additional wrapper styles if needed
          wrapperClass="" // Additional wrapper class if needed
        />
      </div>
    );
  };

  handlesShowSuccessModal = (show) => {
    this.setState({ sucessOpen: show });
    //console.log("Value==================>", show);
  };

  handleLoading = (loading) => {
    this.setState({ showLoading: loading });
  };

  handleShowShare = () => {
    this.setState({ showShare: true });
    //console.log("YES SHARE CLICKED----------------->>>>");
  };

  handleClose = () => {
    this.setState({ showShare: false });
    //console.log("YES SHARE CLICKED----------------->>>>");
  };

  sucessHandleClose = () => {
    this.setState({ sucessOpen: false });
  };

  getColumns = (total, estimateTotals) => {
    const {
      classes,
      evaluation,
      width,
      onAdd,
      onShowNotes,
      isWideView,
      isNarrowView,
    } = this.props;

    const list = (evaluation && evaluation.items) || [];
    const subpackages = get(evaluation, "evaluation.subpackages") || [];

    let columns = [
      {
        id: "image",
        minWidth: 500,
        maxWidth: 600,
        Header: (
          <Grid
            container
            direction="row"
            alignItems={"center"}
            wrap={"nowrap"}
            justifyContent={isNarrowView ? "space-between" : undefined}
            style={{ minWidth: "fit-content" }}
          >
            <Grid item>
              <Button
                variant={"text"}
                className={"secondary-button"}
                style={{ height: 33 }}
                onClick={onAdd}
                disabled={!evaluation}
              >
                <Typography
                  color="inherit"
                  variant="button"
                  id={"equipmentList.newItem.label"}
                />
              </Button>
            </Grid>
            <Grid item className={classes.titleFrame}>
              <Typography
                className={`${classes.titleStyle} default-text-small-bold`}
                id={"equipmentList.headerText.label"}
                values={{ count: list.length }}
              />
            </Grid>
            <Hidden smUp>
              <Grid
                container
                item
                alignItems={"center"}
                justifyContent={"center"}
                style={{ marginLeft: 4, height: "100%", width: "unset" }}
              >
                <Select
                  name={"sort"}
                  value={this.state.sort || ""}
                  classes={{
                    select: classes.select,
                  }}
                  style={{ height: "auto" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onChange={this.handleChange}
                  input={
                    <Input
                      name="status"
                      disableUnderline={true}
                      style={{ marginRight: 8, fontSize: 18 }}
                    />
                  }
                  displayEmpty
                  renderValue={(value) => (
                    <Typography
                      className={classes.menuText}
                      color="inherit"
                      variant="body1"
                      id={
                        MAP_SORT_TO_LABEL[value] ||
                        "equipmentListSort.placeholder.label"
                      }
                    />
                  )}
                >
                  <Typography
                    className={"nonessential-title-caps"}
                    color="inherit"
                    variant="body1"
                    id={"equipmentListSort.title.label"}
                    style={{ marginLeft: 8, marginTop: 8, width: 175 }}
                  />
                  <MenuItem value={"updated"}>
                    <Typography
                      className={classes.menuText}
                      color="inherit"
                      variant="body1"
                      id={"equipmentListSort.updated.label"}
                    />
                  </MenuItem>
                  <MenuItem value={"created"}>
                    <Typography
                      className={classes.menuText}
                      color="inherit"
                      variant="body1"
                      id={"equipmentListSort.created.label"}
                    />
                  </MenuItem>
                  <MenuItem value={"location"}>
                    <Typography
                      className={classes.menuText}
                      color="inherit"
                      variant="body1"
                      id={"equipmentListSort.location.label"}
                    />
                  </MenuItem>
                  <MenuItem value={"serial"}>
                    <Typography
                      className={classes.menuText}
                      color="inherit"
                      variant="body1"
                      id={"equipmentListSort.serial.label"}
                    />
                  </MenuItem>
                  <MenuItem value={"vin"}>
                    <Typography
                      className={classes.menuText}
                      color="inherit"
                      variant="body1"
                      id={"equipmentListSort.vin.label"}
                    />
                  </MenuItem>
                  <MenuItem value={"inventoryId"}>
                    <Typography
                      className={classes.menuText}
                      color="inherit"
                      variant="body1"
                      id={"equipmentListSort.inventoryId.label"}
                    />
                  </MenuItem>
                  <MenuItem value={"stickerId"}>
                    <Typography
                      className={classes.menuText}
                      color="inherit"
                      variant="body1"
                      id={"equipmentListSort.stickerId.label"}
                    />
                  </MenuItem>
                  <MenuItem value={"year"}>
                    <Typography
                      className={classes.menuText}
                      color="inherit"
                      variant="body1"
                      id={"equipmentListSort.year.label"}
                    />
                  </MenuItem>
                  <MenuItem value={"make"}>
                    <Typography
                      className={classes.menuText}
                      color="inherit"
                      variant="body1"
                      id={"equipmentListSort.make.label"}
                    />
                  </MenuItem>
                  <MenuItem value={"model"}>
                    <Typography
                      className={classes.menuText}
                      color="inherit"
                      variant="body1"
                      id={"equipmentListSort.model.label"}
                    />
                  </MenuItem>
                  <MenuItem value={"hours"}>
                    <Typography
                      className={classes.menuText}
                      color="inherit"
                      variant="body1"
                      id={"equipmentListSort.hours.label"}
                    />
                  </MenuItem>
                  <MenuItem value={"miles"}>
                    <Typography
                      className={classes.menuText}
                      color="inherit"
                      variant="body1"
                      id={"equipmentListSort.miles.label"}
                    />
                  </MenuItem>
                </Select>
              </Grid>
            </Hidden>
            <Grid item className={classes.buttonFrame}>
              <Button
                className={"secondary-minimal-button-small no-print"}
                onClick={onShowNotes}
                disabled={!evaluation}
                style={{ padding: 2 }}
              >
                <Typography
                  color="inherit"
                  variant="button"
                  style={{ whiteSpace: "normal" }}
                  id={
                    isWideView
                      ? "equipmentList.notes.label"
                      : "equipmentList.details.label"
                  }
                />
              </Button>
            </Grid>
          </Grid>
        ),
      },
    ];
    return columns;
  };

  render() {
    const { classes, total, estimateTotals, evaluation, onAdd } = this.props;
    const { anchorEl, subpackageTotals, showShare } = this.state;
    const open = Boolean(anchorEl);

    return (
      <Fragment>
        {open && (
          <SubpackagesPopover
            open={open}
            classes={{
              paper: this.props.classes.paper,
            }}
            anchorEl={anchorEl}
            onClose={this.handlePopoverClose}
            subpackageTotals={subpackageTotals}
          />
        )}
        <AppBar position="static" color="inherit" elevation={2}>
          <div className={classes.headerContent}>
            <ReactTable
              className={`${classes.equipmentHeaderStyle} equipment-list`}
              defaultPageSize={10000}
              minRows={0}
              sortable={false}
              resizable={false}
              multiSort={false}
              noDataText={""}
              getNoDataProps={() => ({
                style: { height: 0, width: 0, padding: 0 },
              })}
              data={[]}
              showPagination={false}
              columns={this.getColumns(total, estimateTotals)}
            />
            <Button
              variant="text"
              className={classNames(
                "share-secondary-button",
                classes.buttonContainer
              )}
              style={{ height: 33 }}
              onClick={this.handleShowShare}
              disabled={!evaluation}
            >
              <Typography
                color="inherit"
                variant="button"
                id={"equipmentList.shareCatalog.label"}
              />
              <GoShareAndroid className={classes.shareIcon} />
            </Button>
          </div>
        </AppBar>
        {showShare && (
          <ShareModal
            handleClose={this.handleClose}
            open={showShare}
            eval_id={this.props.evaluation.evaluation.eval_id}
            evaluation={evaluation}
            handlesShowSuccessModal={this.handlesShowSuccessModal}
            handleLoading={this.handleLoading}
          />
        )}
        {this.state.sucessOpen && this.handleSucessModal()}
        {this.state.showLoading && this.loaderComp()}
      </Fragment>
    );
  }
}

export default withStyles(styles)(AssetTableHeader);
