import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {findIndex, toNumber} from 'lodash';
import debounce from 'lodash/debounce';
import defer from 'lodash/defer';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, {useState, useEffect, useRef} from 'react';
import {useIntl} from 'react-intl';
import {
   SAVE_EVALUATION, SAVE_ESTIMATE, ESTIMATE_DEBOUNCE_MILLIS, SUCCESS_DECORATION_DURATION
} from '../../../Constants';
import DisplayError from '../../../fhg/components/DisplayError';
import Typography from '../../../fhg/components/Typography';
import {formatMessage} from '../../../fhg/utils/Utils';
import {requestForServer} from '../../../Utils/ServerUtil';
import {getYourEstimate, getConsensusEstimate} from '../EquipmentUtil';
import FormattedTextFieldXL from './FormattedTextFieldXL';
import {fontStyle} from './Styles';

const useStyles = makeStyles(theme => ({
   enterEstimate: {
      textDecoration: 'underline',
      fontSize: `${theme.size.font['text-small']}rem !important`,
   },
   formattedText: {
      minWidth: 92,
      maxWidth: 124,
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
         minWidth: 100,
         maxWidth: 144,
      },
      '@media print': {
         maxWidth: 110,
      },
      '& input': {
         ...fontStyle(theme, 'numbers', 0.875, 0),
      },
   },
   savedStyle: {
      color: theme.palette.button.standard.success,
      fontSize: `${theme.size.font['item-title']}rem !important`,
      position: 'absolute',
      zIndex: 1001,
      marginLeft: 8,
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
         top: 7,
      },
   },
}), 'EditableEstimatesStyles');

export default function EditableEstimate({estimateType, value, inputName, labelKey, label, evalItem, evaluation, isConsensus, onSave, onTab, marginRight, allowButton = true, ...textFieldProps}) {

   const classes = useStyles();
   const intl = useIntl();

   const [showEdit, setShowEdit] = useState(Number(value) > 0);
   const [isChanged, setIsChanged] = useState(false);
   const [originalValue] = useState(value);
   const [editValue, setEditValue] = useState(value);
   const [isSaved, setIsSaved] = useState(false);
   const [error, setError] = useState();
   const [errorId, setErrorId] = useState();
   const [showError, setShowError] = useState(false);

   const handleKeyDown = (event) => {
      switch (event.key) {
         case 'Tab':
            if (onTab) {
               event.preventDefault();
               event.stopPropagation();

               onTab(event);
            }
            break;
         default:
         //Do nothing
      }
   };

   /**
    * Submits the final (consensus) estimate changes after the debounce timeout.
    */
   const handleConsensusSubmit = async (estimate) => {

      const update = {
         consensus: Number(estimate) || ' ',
         estimate_type_name: estimateType.name,
      };

      const consensus = getConsensusEstimate(evalItem, estimateType);
      if (consensus.name) {
         consensus.value = toNumber(estimate);
      }

      await requestForServer(
         SAVE_EVALUATION.format({uri: evaluation.uri, itemId: get(evalItem, 'item.item_id')}), 'put', update);
      // const consensus = getConsensusEstimate(evalItem, estimateType);
      if (consensus.name) {
         consensus.value = toNumber(estimate);
      }
   };

   /**
    * Submits the changes after the debounce timeout.
    */
   const submit = async (isChanged, onSave, evalItem, estimate, isConsensus) => {
      if (isChanged) {
         onSave(true, false, isConsensus, false);
         try {
            if (isConsensus) {
               await handleConsensusSubmit(estimate);
            } else {
               const yourEstimate = getYourEstimate(evalItem, estimateType);

               yourEstimate.value = Number(estimate);
               yourEstimate.estimate_type_name = estimateType.name;

               const result = await requestForServer(SAVE_ESTIMATE.format(evalItem), 'post', yourEstimate);
               if (result && result.data.status === 'success') {
                  let estimates = evalItem.estimates;
                  if (!estimates) {
                     evalItem.estimates = [];
                     estimates = evalItem.estimates;
                  }
                  const index = findIndex(estimates, {estimate_id: result.data.estimate.estimate_id});
                  if (index >= 0) {
                     estimates[index] = result.data.estimate;
                  } else {
                     estimates.push(result.data.estimate);
                  }
               }
            }
            setIsChanged(false);
            setIsSaved(true);
            setTimeout(() => {
               onSave(false, false, isConsensus, false);
               setIsSaved(false);
            }, SUCCESS_DECORATION_DURATION);
         } catch (e) {
            console.log(e);
            onSave(false, true);
            setIsSaved(false);
            setShowError(true);
            setErrorId('equipmentList.save.error');
            setError(e);
         }
      }
   };

   const submitDelayed = useRef(debounce(submit, ESTIMATE_DEBOUNCE_MILLIS)).current;

   //Flush any submits on unmount.
   useEffect(() => {
      return () => {
         if (isChanged) {
            submitDelayed.flush();
         }
      }
   }, [isChanged, submitDelayed]);

   useEffect(() => {
      if (value !== originalValue) {
         setShowEdit(!!value);
         setEditValue(value);
      }
   }, [value, isChanged, originalValue]);

   const handleChange = (event) => {
      setShowEdit(true);
      setEditValue(event.target.value);
      setIsChanged(true);
      submitDelayed(true, onSave, evalItem, event.target.value, isConsensus);
   };

   const handleClick = event => {
      event.stopPropagation();
      event.preventDefault();
      setShowEdit(true);
      defer(() => {
         const elements = document.getElementsByName(inputName);
         if (elements && elements.length >= 1 && isFunction(elements[0].select)) {
            elements[0].select();
         }
      });
   };

   const handleBlur = () => {

      if (!(Number(editValue) > 0)) {
         setShowEdit(false);
      }
      submitDelayed.flush();
   };

   if ((!(Number(editValue) > 0)) && !showEdit && allowButton) {
      return (
         <Grid container className={'no-print'} alignItems={'center'} justifyContent={'center'} style={{height: '100%'}}>
            <Button name={inputName} onClick={handleClick} style={{marginRight}}>
               <Typography className={`editableTextStyle ${classes.enterEstimate}`} color='textSecondary'
                           id={!label ? labelKey : undefined}>
                  {label}
               </Typography>
            </Button>
         </Grid>
      );
   } else {
      return (
         <Grid container alignItems={'center'} justifyContent={'center'} style={{height: '100%'}} spacing={0}>
            {showError && <DisplayError errorId={errorId} error={error}/>}
            <FormattedTextFieldXL
               className={`editableTextStyle ${classes.formattedText}`}
               inputProps={{className: 'editableTextStyle', name: inputName}}
               onBlur={handleBlur}
               onKeyDown={handleKeyDown}
               value={editValue}
               onChange={handleChange}
               margin='dense'
               format={'currency'}
               placeholder={formatMessage(intl, 'equipmentList.estimate.placeholder', '$10,000')}
               // eslint-disable-next-line
               InputProps={{
                  startAdornment: <Fade className={classes.savedStyle} in={isSaved}
                                        timeout={750}><span>Saved</span></Fade>,
               }}
               style={{marginRight}}
               {...textFieldProps}
            />
         </Grid>
      );
   }
}

EditableEstimate.propTypes = {
   value: PropTypes.any,
   onTab: PropTypes.func,
   inputName: PropTypes.string,
   labelKey: PropTypes.string,
};

EditableEstimate.defaultProps = {
   labelKey: 'equipmentList.enterEstimate.label',
};