import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import Typography from '../Typography';

const styles = theme => ({
   contentStyle: {
      flex: '1 1 auto',
      overflow: 'auto',
   },
   spinnerMargin: {
      marginLeft: theme.spacing(0.5),
   },
   actionStyle: {
      margin: 0,
      padding: theme.spacing(1),
      backgroundColor: theme.palette.environment.light.level1.base,
      borderTop: `1px solid ${theme.palette.environment.light.level1.accent}`,
      flex: '0 0 auto',
   },
   fatButtonStyle: {
      padding: '12px 30px !important',
      margin: `0 0 0 ${theme.spacing(1)}px !important`,
      width: 'unset',
      height: 'unset',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
         width: 'auto',
         height: 'auto',
         padding: '12px 24px !important',
      },
   },
   titleStyle: {
      paddingBottom: theme.spacing(3),
      borderBottom: `1px solid ${theme.palette.environment.light.divider}`, //#BFBAAE
      flex: '0 0 auto',
   },
   formStyle: {
      padding: theme.spacing(3, 3, 4),
   },
});

/**
 * The New User dialog which creates a new user.
 */
class ModalDialog extends PureComponent {
   static propTypes = {
      formId: PropTypes.any,                  // The id of the form component
      classes: PropTypes.object.isRequired,// Styles for the component.
      message: PropTypes.string,          // Message to be displayed to the user. Use either message or messageKey but
                                          //    not both.
      messageKey: PropTypes.string,       // Message key of the message to be displayed to the user.
      onSubmit: PropTypes.func,           // Called when the user submits/confirms.
      onClose: PropTypes.func,            // Called when the user closes/cancels.
      open: PropTypes.bool,               // Indicates if the dialog should be open or not.
      title: PropTypes.string,            // Title for the confirmation dialog.
      titleKey: PropTypes.string,         // Localization key for the Title for the confirmation dialog.
      titleValues: PropTypes.object,      // Values for the Title for the confirmation dialog.
      submitLabel: PropTypes.string,      // Label for the submit button.
      submitKey: PropTypes.string,        // Localization key for the submit button label.
      cancelLabel: PropTypes.string,      // Label for the cancel button.
      cancelKey: PropTypes.string,        // Localization key for the cancel button label.
      cancelColorStyle: PropTypes.string, // The class specifying the color of the cancel button. Needs color and
                                          //    background color for all states (e.g. primary-color-button).
      values: PropTypes.object,           // Localization values for the message.
      isSaving: PropTypes.bool,           // Indicates if the saving progress should be shown.
      isEnabled: PropTypes.bool,           // Indicates if the submit button can be enabled. It won't be enabled if
                                           // isSaving is true.
      submitColorStyle: PropTypes.string, // The class specifying the color of the submit button. Needs color and
                                          //    background color for all states (e.g. primary-color-button).
      maxWidth: PropTypes.string,         // The maximum width of the dialog.
      children: PropTypes.any,            // Optional children components.
      isForm: PropTypes.bool,             // Is the modal containing a form? If not, the enter key is handled.
      useCaptureKeydown: PropTypes.bool,
   };

   static defaultProps = {
      open: true,
      isSaving: false,
      isEnabled: true,
      submitLabel: 'OK',
      submitKey: 'ok.button',
      cancelLabel: 'Cancel',
      cancelKey: 'cancel.button',
      submitColorStyle: 'primary-color-button',
      cancelColorStyle: 'minimal-cancel-button',
      maxWidth: 'md',
      isForm: false,
      useCaptureKeydown: true,
   };

   constructor(props, context) {
      super(props, context);

      document.addEventListener('keydown', this.handleKey, {capture: props.useCaptureKeydown});
   }

   componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKey, {capture: this.props.useCaptureKeydown});
   }

   handleKey = (event) => {
      const {onClose, open} = this.props;
      if (!event.defaultPrevented && open) {

         if (event.key === 'Escape' && onClose) {
            event.preventDefault();
            onClose(event);
         } else if (!this.props.isForm && event.key === 'Enter') {
            this.handleSubmit(event);
         }
      }
   };

   handleClickSubmit = event => {
      if (!event.isDefaultPrevented()) {
         this.handleSubmit(event);
      }
   };

   handleSubmit = event => {
      const {onSubmit} = this.props;
      event.preventDefault();
      onSubmit && onSubmit(event);
   };

   render() {
      const {
         classes, open, onClose, isSaving, isEnabled, titleKey, title, submitKey, submitLabel, messageKey, values,
         message, cancelKey, cancelLabel, submitColorStyle, maxWidth, children, isForm, cancelColorStyle, titleValues,
         onSubmit, formId, ...dialogProps
      } = this.props;

      return (
         <Dialog open={open} onClose={onClose} maxWidth={maxWidth}
                 onKeyDown={this.handleKey} {...dialogProps}>
            <Grid container direction={'column'} wrap={'nowrap'} style={{overflow: 'hidden'}}>
               <form id={formId} onSubmit={this.handleSubmit}
                     style={{overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
                  <DialogTitle disableTypography className={classes.titleStyle}>
                     <Typography className={'level-2-heading'} id={titleKey} values={titleValues}>{title}</Typography>
                  </DialogTitle>
                  <DialogContent className={classes.contentStyle}>
                     <Grid container className={classes.formStyle} direction={'column'} wrap={'nowrap'}>
                        {(messageKey || message) && (
                           <Typography className={'normal-default-text'} id={messageKey}
                                       values={values}>{message}</Typography>
                        )}
                        {children}
                     </Grid>
                  </DialogContent>
                  <DialogActions className={classes.actionStyle} spacing={1}>
                     <Button className={`${classes.fatButtonStyle} button ${cancelColorStyle}`} disabled={isSaving}
                             onClick={onClose}>

                        <Typography color='inherit' id={cancelKey}>{cancelLabel}</Typography>
                     </Button>
                     {onSubmit && (
                        <Button className={`${classes.fatButtonStyle} button ${submitColorStyle}`} type='submit'
                                disabled={isSaving || !isEnabled}
                                onClick={(!isForm && this.handleClickSubmit) || undefined}>
                           <Typography color='inherit' id={submitKey}>{submitLabel}</Typography>
                           {isSaving &&
                           <CircularProgress className={classes.spinnerMargin} size={15} thickness={2.5}/>}
                        </Button>
                     )}
                  </DialogActions>
            </form>
            </Grid>
         </Dialog>
      );
   }
}

export default withStyles(styles)(ModalDialog);
