import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { isAdminOnly, isMultipleOrganization } from "../../../../Utils/ServerUtil";
import { CognitoIdToken } from "amazon-cognito-identity-js"; // Import the correct types

const FetchEvalDataItem = (itemId: string) => {
  const [evaluationData, setEvaluationData] = useState<any | null>(null);
  const [tokenRefreshError, setTokenRefreshError] = useState(false);

  useEffect(() => {
    const fetchEvaluationitem = async () => {
      let jwtToken: string | undefined;
      let tokenRefreshFailed = false;

      try {
        const session = await Auth.currentSession();
        const idToken: CognitoIdToken = session.getIdToken(); // Correct way to get the ID token
        jwtToken = idToken.getJwtToken(); // Get the actual JWT token
      } catch (e) {
        try {
          const session = await Auth.currentSession();
          const idToken: CognitoIdToken = session.getIdToken();
          jwtToken = idToken.getJwtToken();
        } catch (e) {
          console.error("Error refreshing token", e);
          tokenRefreshFailed = true;
          setTokenRefreshError(true);
        }
      }

      const headers: Record<string, string> = {
        accept: "application/json",
      };

      if (jwtToken) {
        headers["authorization"] = jwtToken;
      }

      if (isAdminOnly() || isMultipleOrganization()) {
        const organization = localStorage.getItem("hw." + window.btoa("organization"));
        if (organization && window.atob(organization)) {
          headers["HW-Organization"] = window.atob(organization);
        }
      }

      const options = {
        method: "GET",
        headers: headers,
      };

      const url = `${process.env.REACT_APP_API_ENDPOINT}/items/${itemId}`;

      try {
        const response = await fetch(url, options);
        if (response.ok) {
          const itemData = await response.json();
          setEvaluationData(itemData);
        } else {
          console.error("Request failed with status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching evaluation:", error);
      }
    };

    if (itemId) {
      fetchEvaluationitem();
    }
  }, [itemId]);

  return { evaluationData, tokenRefreshError };
};

export default FetchEvalDataItem;
