//import { Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
//import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
//import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import { NotificationImportant } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import NotificationsIcon from "@material-ui/icons/Notifications";
import classNames from "classnames";
import { isEqual, find } from "lodash";
import get from "lodash/get";
import map from "lodash/map";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { injectIntl } from "react-intl";
import { matchPath } from "react-router";
import { withRouter } from "react-router-dom";
import {
  DEFAULT_LOCATION,
  SUCCESS_DECORATION_DURATION,
  CLOSE_DELAY_FOR_SUBSCRIBE,
  CATALOG_HEADER_HEIGHT,
  SEND_TO_PURPLEWAVE,
  EDIT_ITEM_PATH,
  //GOOGLE_SHEETS_PATH,
} from "../../../Constants";
//import FHGTypography from "../../../fhg/components/Typography";
import Typography from "../../../fhg/components/Typography";
import Feature from "../../../fhg/security/Feature";
import NotFeature from "../../../fhg/security/NotFeature";
//import ExportCsv from "../../../fhg/utils/ExportUtils";
import {
  //sortMethod,
  getImageObjects,
  //formatMessage,
} from "../../../fhg/utils/Utils";
import GoogleSheets from "../../../Utils/GoogleUtil";
import {
  requestForServer,
  CUSTOMER_ENV,
  //SEE_ESTIMATES,
} from "../../../Utils/ServerUtil";
import {
  //downloadCatalogPdf,
  submitCatalogToIms,
  reSumbmitCatalogToIms,
} from "../../../Utils/SubmitUtil";
import CollaboratorPopover from "../components/CollaboratorPopover";
import ErrorSnackbar from "../components/ErrorSnackbar";
import WorkflowStatusSelect from "../components/WorkflowStatusSelect";
//import { sortedItems, currentSortKey } from "./EquipmentList";
import NewEvaluationDialog from "./NewEvaluationDialog";
import {
  isAdminOnly,
  isInternalTeam,
  isMultipleOrganization,
} from "../../../Utils/ServerUtil";
import { Auth } from "aws-amplify";
import eventCapture from "../../../eventList";
import { posthogCapture } from "../../../PostHogFunction";
import { useEvaluationIdStore } from "../../zustandState/ZustandStore";
import { debounce } from "lodash";

const styles = (theme) => ({
  savedLabel: {
    color: theme.palette.content.subtle,
    marginRight: theme.spacing(2),
    maxWidth: 140,
    marginTop: "auto",
    marginBottom: "auto",
  },
  savedTime: {
    animation: `$successText ${SUCCESS_DECORATION_DURATION / 1000}s ease`,
  },
  "@keyframes successText": {
    from: {
      color: theme.palette.button.standard.success,
    },
    "90%": {
      color: theme.palette.button.standard.success,
    },
    to: {
      color: theme.palette.content.subtle,
    },
  },
  header: {
    borderBottom: `1px solid ${theme.palette.environment.light.level1.accent}`,
    padding: theme.spacing(0, 3),
    flex: "0 0 auto",
    // Add space for the menu button.
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(1),
    },
    height: CATALOG_HEADER_HEIGHT,
  },
  menuText: {
    color: `${theme.palette.content.default} !important`,
    marginRight: 16,
  },
  exportMenuText: {
    color: "#4f2682 !important",
    fontDecoration: "underline",
    fontWeight: "bold",
    marginRight: 16,
  },
  menuButtonStyle: {
    marginRight: -28,
  },
  containerStyle: {
    width: "auto",
  },
  verticalDividerStyle: {
    borderLeft: `1px solid ${theme.palette.environment.light.level0.accent} !important`,
    height: "100%",
    minHeight: 44,
    width: 2,
    margin: `0 ${theme.spacing(2)}px`,
  },
  smallContainer: {
    display: "flex",
    flexWrap: "nowrap",
    boxSizing: "border-box",
  },
  spinnerMargin: {
    marginLeft: theme.spacing(0.5),
  },
  subscribeSnackbarStyle: {
    backgroundColor: `${theme.palette.environment.light.level0.base} !important`,
    "& p:first-of-type": {
      display: "inline",
    },
    "& p": {
      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        margin: "0 0",
      },
      "margin-block-start": 0,
      "margin-block-end": 0,
      "-webkit-margin-start": 0,
      "-webkit-margin-end": 0,
      "-webkit-margin-before": 0,
      "-webkit-margin-after": 0,
    },
  },
  notificationStyle: {
    marginLeft: theme.spacing(2),
    color: "#4f2682 !important",
  },
  ownerStyle: {
    composes: "normal-default-text",
    fontSize: `${theme.size.font["text-small"]}rem !important`,
  },
  paper: {
    padding: theme.spacing(1),
  },
  collaboratorStyle: {
    cursor: "pointer",
  },
  checkboxStyle: {
    color: `${theme.palette.environment.light.divider} !important`,
    padding: 6,
  },
  checkedStyle: {
    color: `${theme.palette.button.standard.primary} !important`,
  },
  checkboxPaddingStyle: {
    "& p": {
      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        margin: "0 0",
      },
      "margin-block-start": 0,
      "margin-block-end": 0,
      "-webkit-margin-start": 0,
      "-webkit-margin-end": 0,
      "-webkit-margin-before": 0,
      "-webkit-margin-after": 0,
    },
  },
  menuItemStyle: {
    height: 35,
    paddingTop: 0,
    paddingBottom: 0,
  },
});

/**
 * Styles for the NotificationSnackbar.
 */
const useStyles = makeStyles(
  (theme) => ({
    subscribeSnackbarStyle: {
      backgroundColor: `${theme.palette.environment.light.level0.base} !important`,
      "& p:first-of-type": {
        display: "inline",
      },
      "& p": {
        "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)":
          {
            margin: "0 0",
          },
        "margin-block-start": 0,
        "margin-block-end": 0,
        "-webkit-margin-start": 0,
        "-webkit-margin-end": 0,
        "-webkit-margin-before": 0,
        "-webkit-margin-after": 0,
      },
    },
    paper: {
      padding: theme.spacing(1),
    },
  }),
  { name: "NotificationSnackbarStyles" }
);

/**
 * Component to show notifications (e.g. Zip and Google Sheets).
 *
 * @param open Indicates if the Snackbar should be shown.
 * @param onClose Callback when the Snackbar is closed.
 * @param messageKey The localization key for the message.
 * @param messageValues The localization values for the message.
 * @param autoHideDuration The duration before hiding the Snackbar. Null will not auto hide.
 * @param actions Additional actions beside the close button.
 * @return {JSX.Element} The component.
 * @constructor
 */
function NotificationSnackbar({
  open,
  onClose,
  messageKey,
  messageValues,
  autoHideDuration = CLOSE_DELAY_FOR_SUBSCRIBE,
  actions = [],
}) {
  const classes = useStyles();

  return (
    <Snackbar
      ContentProps={{
        classes: {
          root: `${classes.subscribeSnackbarStyle} normal-default-text`,
        },
      }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      message={
        <Grid container direction={"row"} alignItems={"center"} spacing={2}>
          <Grid item>
            <NotificationImportant style={{ verticalAlign: "middle" }} />
          </Grid>
          <Grid item>
            <Typography
              id={messageKey}
              values={messageValues}
              color={"inherit"}
              display={"inline"}
            />
          </Grid>
        </Grid>
      }
      action={[
        ...actions,
        <IconButton
          key="closeMessage"
          aria-label="Close Notification Message"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );
}

NotificationSnackbar.propTypes = {
  classes: PropTypes.any,
  open: PropTypes.any,
  onClose: PropTypes.func,
};

/**
 * Component to show the header above the evaluation equipment list.
 */
class EquipmentListHeader extends PureComponent {
  static propTypes = {
    status: PropTypes.any,
    classes: PropTypes.any,
    primaryContact: PropTypes.string,
    allowExport: PropTypes.bool,
    saving: PropTypes.bool,
    savedTime: PropTypes.any,
    id: PropTypes.string,
    evaluation: PropTypes.object,
    tableRef: PropTypes.any,
  };

  static defaultProps = {
    allowExport: true,
  };

  constructor(props) {
    super(props);

    this.fetchCatalogFormData = debounce(
      this.fetchCatalogFormData.bind(this),
      300
    );

    this.state = {
      isExporting: false,
      collaboratorMessage: this.getCollaboratorMessage(props),
      isIncludeEstimates: false,
      isIncludePhotos: false,
      showNotification: false,
      evaluation_id: get(this.props, "evaluation.evaluation.eval_id", ""),
      customerNumber: "",
      opportunityId: "",
      ownerName: "",
      owner: "",
      contact: "",
      globalStoreEvalId: null,
      evalData: {},
    };

    if (get(props, "location.state.isNewEval2")) {
      this.state.isNewOpen = true;
    }
    this.state.isEditOpen = !!this.state.isNewOpen;
  }
  componentDidMount() {
    // Check URL for evaluation_id
    const urlParams = new URLSearchParams(window.location.search);
    const evaluationIdFromUrl = urlParams.get("evaluation_id");

    if (evaluationIdFromUrl) {
      this.setState({ evaluation_id: evaluationIdFromUrl });
    }
    this.checkZustandState();
    this.fetchCatalogFormData();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("ZUST ID: ", this.state.evaluation_id);
    const prevSubscriptions = get(
      prevProps,
      "evaluation.evaluation.subscriptions"
    );
    const currentSubscriptions = get(
      this.props,
      "evaluation.evaluation.subscriptions"
    );

    if (!isEqual(prevSubscriptions, currentSubscriptions)) {
      this.setState({
        collaboratorMessage: this.getCollaboratorMessage(this.props),
      });
    }

    if (
      prevState.globalStoreEvalId !== this.state.globalStoreEvalId ||
      prevState.evaluation_id !== this.state.evaluation_id
    ) {
      // Add evaluation_id to the URL
      const { evaluation_id } = this.state;
      const url = new URL(window.location);
      url.searchParams.set("evaluation_id", evaluation_id);
      window.history.replaceState(null, "", url.toString());
      if (this.state.evaluation_id === null) {
        this.fetchCatalogFormData();
      }
    }
  }

  checkZustandState = () => {
    const globalStoreEvalId = useEvaluationIdStore.getState().evaluationId;

    if (
      globalStoreEvalId &&
      globalStoreEvalId !== this.state.globalStoreEvalId
    ) {
      this.setState({
        globalStoreEvalId,
        evaluation_id: globalStoreEvalId, // Update evaluation_id here as well
      });
    }
  };

  onEditClose = (event) => {
    const { isNewOpen } = this.state;

    this.setState(
      {
        isEditOpen: false,
        isNewOpen: false,
        collaboratorMessage: this.getCollaboratorMessage(this.props),
      },
      () => {
        if (!!event && isNewOpen) {
          this.props.history.replace(DEFAULT_LOCATION);
        }
      }
    );
  };

  onEditOpen = () => {
    //window?.posthog?.capture(eventCapture.EDIT_EVALUATION);
    posthogCapture(eventCapture.EDIT_EVALUATION);
    this.setState({ isEditOpen: true, isNewOpen: false });
  };
  x;

  handleExportClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleExportClose = () => {
    this.setState({ anchorEl: null });
  };

  fetchCatalogFormData = async () => {
    const { evaluation_id } = this.state;
    let jwtToken;
    let tokenRefreshError = false;

    try {
      const session = await Auth.currentSession();
      jwtToken = session.idToken.jwtToken;
    } catch (e) {
      try {
        const session = await Auth.currentSession();
        jwtToken = session.idToken.jwtToken;
      } catch (e) {
        //console.log("Error refreshing token", e);
        tokenRefreshError = true;
        // Allow to go through without jwtToken for server requests not requiring an authenticated user.
      }
    }

    const headers = {
      authorization: jwtToken,
      accept: "application/json",
    };
    // This adds a header for selected organization_ID or _Key which is stored in local storage
    if (isAdminOnly() || isMultipleOrganization()) {
      const organization = localStorage["hw." + window.btoa("organization")];
      if (organization && window.atob(organization)) {
        headers["HW-Organization"] = window.atob(organization);
      }
    }
    const options = {
      method: "GET",
      headers: headers,
    };
    console.log("ZUST ID data 2: ", evaluation_id);
    const url = `${process.env.REACT_APP_API_ENDPOINT}/evaluations/${evaluation_id}`;

    // console.log("url====> ",url);
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        const data = await response.json();
        console.log("ZUST ID data +++++++++++++::::::::::========>", data);
        this.setState(
          {
            customerNumber: data.evaluation?.owner_id,
            opportunityId: data.evaluation?.opportunity_id,
            ownerName: data?.evaluation?.owner_name,
            owner: data?.evaluation?.owner,
            contact: data?.evaluation?.primary_contact,
            evalData: data,
          },
          () => {
            console.log(
              "TEST TEST TEST-------",
              this.state.ownerName,
              this.state.opportunityId,
              this.state.customerNumber
            );
          }
        );
      } else {
        // Handle the error if the response is not ok
        console.log("Request failed with status:", response.status);
        return null;
      }
    } catch (error) {
      // Handle any network or other errors
      console.error("Error:", error);
    }
  };

  /**
   * Use the DropboxSaver to upload the photos to Dropbox.
   * @param files The photos to upload.
   * @return {Promise<unknown>} The promise for the completion of the upload.
   */
  dropboxSaver = (files) => {
    return new Promise((resolve, reject) => {
      const options = {
        files,
        success: resolve,
        cancel: () => reject(new Error("User canceled.")),
        error: reject,
      };
      window.Dropbox.save(options);
    });
  };

  /**
   * Callback when the user selects to export the photos to Dropbox.
   *
   * @param event The user event indicating the photos export to Dropbox.
   * @return {Promise<void>} the promise for the completion of the upload to Dropbox.
   */
  handleDropboxSaver = async () => {
    try {
      this.setState({ isExporting: true });

      const match = matchPath(this.props.location.pathname, {
        path: EDIT_ITEM_PATH,
        exact: true,
        strict: true,
      });
      const item_id = get(match, "params.id");

      if (item_id) {
        const asset = find(this.props.evaluation.items, { item_id });

        if (asset) {
          const images = getImageObjects(asset);
          const fileUrls = map(images, (image) => ({
            url: get(image, "sizes.original"),
            filename: image.original_filename,
          }));
          await this.dropboxSaver(fileUrls);
        } else {
          console.log("Could not find the item");
          this.setState({
            errorId: "equipmentList.exportDropboxSaver.error",
            showError: true,
            message: "Could not find item",
          });
        }
      } else {
        console.log(
          "Called handleDropboxSaver from URL that does not have an item"
        );
        this.setState({
          errorId: "equipmentList.exportDropboxSaver.error",
          showError: true,
          message: "URL with no item",
        });
      }
    } catch (error) {
      this.setState({
        errorId: "equipmentList.exportDropboxSaver.error",
        showError: true,
        message: error && error.message,
      });
    } finally {
      this.setState({ isExporting: false });
    }
  };

  /**
   * Handle the user clicking on the export menu.
   * @param name the name of the export.
   * @return {function(*=): void}
   */
  handleMenuItemClick = (name) => (event) => {
    this.setState({ anchorEl: null }, () => {
      if (name === "dropboxSaver") {
        this.handleDropboxSaver();
      } else if (name === "toZip") {
        this.handleToZip();
      } else if (name === "submitToIms") {
        console.log("Submit to IMS");
        this.handleSubmitToIms();
      } else {
        this.handleExport(event, name);
      }
    });
  };

  handleSubmitToIms = async () => {
    posthogCapture(eventCapture.SUBMIT_CATALOG_TO_IMS);

    const { evaluation } = this.props;
    const evaluationId = get(
      evaluation,
      "evaluation.eval_id",
      get(evaluation, "evaluation.PK")
    );
    console.log(evaluationId);

    try {
      this.setState({ isExporting: true });
      await submitCatalogToIms(evaluationId);
      this.setState({
        showNotification: true,
        notificationKey: "equipmentList.submitToIms.text",
        notificationDuration: undefined,
        notificationAction: undefined,
      });
    } catch (e) {
      this.setState({
        errorId: "equipmentList.submitToIms.error",
        showError: true,
        message: e.message || "N/A",
      });
    } finally {
      this.setState({ isExporting: false });
    }
  };

  handleResendToIMS = async () => {
    posthogCapture(eventCapture.RESUBMIT_EVALUATION);
    const { evaluation } = this.props;
    const evaluationId = evaluation
      ? get(evaluation, "evaluation.eval_id", get(evaluation, "evaluation.PK"))
      : this.state.evalData.evaluation.eval_id;
    console.log("RESEND TO IMS============================>", evaluationId);

    try {
      this.setState({ isExporting: true });
      await reSumbmitCatalogToIms(evaluationId);
      this.setState({
        showNotification: true,
        notificationKey: "equipmentList.reSubmitToIms.text",
        notificationDuration: undefined,
        notificationAction: undefined,
      });
    } catch (e) {
      this.setState({
        errorId: "equipmentList.reSubmitToIms.error",
        showError: true,
        message: e.message || "N/A",
      });
    } finally {
      this.setState({ isExporting: false });
    }
  };

  /**
   * Callback when the user is signing out of Google.
   */
  handleSignOutClick = (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    this.setState({ anchorEl: null }, () => {
      GoogleSheets().signOut();
    });
  };

  /**
   * Handle checkbox change event to mark photos included.
   * @param event The checkbox change event.
   */
  handleCheckboxChange = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const name = event.target.name;
    this.setState({ [name]: event.target.checked });
  };

  /**
   * Handle checkbox click event to mark photos included.
   *
   * Note:
   * Checkbox acts oddly in the menu item. The onChange is called on the first click, but onClick on all following
   * clicks. onClick also gets called when clicking the label.
   *
   *  @param event The checkbox click event.
   */
  handleCheckboxClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const name = event.target.name;
    this.setState({ [name]: !this.state[name] });
  };

  /**
   * Handle the label click. The event will be automatically passed on to the checkbox, but stop propagation to the
   * menu item.
   *
   * @param event The label click event.
   */
  handleLabelClick = (event) => {
    event.stopPropagation();
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSubscribeSnackbar: false });
  };

  getCollaboratorMessage = (props) => {
    const { evaluation } = props;

    let collaboratorMessage;
    let collaboratorCount;

    //Get the number of collaborators. Remove the authenticated user if listed as a collaborator.
    const collaborators = evaluation
      ? get(evaluation, "evaluation.shared_with_users", [])
      : this.state.evalData?.evaluation?.shared_with_users;
    const primaryContactId = evaluation
      ? get(evaluation, "evaluation.primary_contact_id")
      : this.state.evalData?.evaluation?.primary_contact_id;
    if (primaryContactId) {
      const indexOfUser = collaborators.indexOf(primaryContactId);
      collaboratorCount =
        indexOfUser >= 0 ? collaborators.length - 1 : collaborators.length;
      collaboratorMessage = (
        <Typography
          variant="inherit"
          className={"subtle-text-small"}
          display={"inline"}
          id="equipmentList.primaryContactCollaborators.label"
          values={{ count: collaboratorCount }}
        />
      );
    } else if (collaborators.length > 1) {
      collaboratorMessage = (
        <Typography
          variant="inherit"
          className={"subtle-text-small"}
          display={"inline"}
          id="equipmentList.primaryContactCollaboratorsOldEval.label"
        />
      );
    }
    return collaboratorMessage;
  };

  /**
   * Show the collaborators for the catalog.
   *
   * @param event The event that triggers the show collaborators.
   */
  handleShowCollaborators = (event) => {
    this.setState({ collaboratorsAnchorEl: event.currentTarget });
  };

  /**
   * Close the collaborators popover.
   */
  handleCloseCollaborators = () => {
    this.setState({ collaboratorsAnchorEl: null });
  };

  /**
   * Send the catalog to PurpleWave.
   */
  handleSendtoPurpleWave = () => {
    const evalId = get(
      this.props,
      "evaluation.evaluation.eval_id",
      get(this.props, "evaluation.evaluation.PK")
    );

    requestForServer(SEND_TO_PURPLEWAVE.format({ evalId }), "post");
  };

  /**
   * Close the error.
   */
  handleErrorClose = () => {
    this.setState({ showError: false });
  };

  render() {
    const {
      theme,
      classes,
      saving,
      savedTime,
      allowExport,
      evaluation,
      location,
    } = this.props;
    const {
      isEditOpen,
      isNewOpen,
      anchorEl,
      isExporting,
      collaboratorMessage,
      collaboratorsAnchorEl,
      showError,
      errorId,
      message,
      showNotification,
      notificationKey,
      notificationValues,
      notificationDuration,
      notificationAction,
      opportunityId,
      customerNumber,
      ownerName,
      owner,
      contact,
      evalData,
    } = this.state;

    //console.log("Eval Data", evalData);

    // Check if `evaluation` has items or default to `evalData`
    const effectiveEvaluation =
      evaluation && Object.keys(evaluation).length > 1 ? evalData : evaluation;

    const currentState = saving
      ? "equipmentList.saving.label"
      : savedTime
      ? "equipmentList.saved.label"
      : undefined;
    const isSubscribed = get(evaluation, "subscribed", false);
    const isAssetDetailPath = matchPath(location.pathname, {
      path: EDIT_ITEM_PATH,
      exact: true,
      strict: true,
    });

    if (!get(location, "state.isZoomed")) {
      return (
        <Grid
          container
          className={classes.header}
          justifyContent={"space-between"}
          alignItems={"center"}
          spacing={0}
          direction={"row"}
          wrap={"nowrap"}
        >
          {showError && (
            <ErrorSnackbar
              open={showError}
              onClose={this.handleErrorClose}
              errorId={errorId}
              values={{ message }}
              enableRefresh={true}
            />
          )}
          {showNotification && (
            <NotificationSnackbar
              open={showNotification}
              onClose={this.handleCloseZipMessage}
              autoHideDuration={notificationDuration}
              messageKey={notificationKey}
              messageValues={notificationValues}
              actions={[notificationAction]}
            />
          )}
          <Grid
            container
            item
            className={classes.smallContainer}
            wrap={"nowrap"}
            style={{ width: "auto" }}
          >
            {isEditOpen && (
              <NewEvaluationDialog
                open={isEditOpen}
                onClose={this.onEditClose}
                subscribed={isSubscribed}
                evaluation={!isNewOpen && evaluation}
                customerNumber={customerNumber}
                ownerName={ownerName}
                opportunityId={opportunityId}
              />
            )}
            <Grid item style={{ marginTop: -3 }}>
              <Typography variant="inherit" className={classes.ownerStyle}>
                {ownerName ?? get(evaluation, "evaluation.owner_name", "N/A")}
              </Typography>
              <Typography variant="inherit" className={"subtle-text-micro"}>
                {owner ?? get(evaluation, "evaluation.owner_name", "N/A")}
              </Typography>
            </Grid>
            <div className={classes.verticalDividerStyle} />
            <Grid item style={{ marginTop: -3 }}>
              <Typography
                variant="inherit"
                className={"nonessential-title-caps"}
              >
                CUSTOMER NUMBER
              </Typography>
              <a
                href={`https://cliq.purplewave.com/c/cus.php?c=${
                  customerNumber ?? get(evaluation, "evaluation.owner_id", "")
                }`}
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation();
                  posthogCapture(eventCapture.CUSTOMER_NUMBER_CLICKED);
                }}
              >
                <Typography variant="inherit" className={"subtle-text-micro"}>
                  {customerNumber ?? get(evaluation, "evaluation.owner_id", "")}
                </Typography>
              </a>
            </Grid>
            <Grid
              container
              item
              className={classes.smallContainer}
              style={{ width: "auto" }}
            >
              <div className={classes.verticalDividerStyle} />
              <Grid item>
                <Typography
                  variant="inherit"
                  className={"nonessential-title-caps"}
                  id={"equipmentList.primaryContact.label"}
                  display={"block"}
                >
                  Primary Contact
                </Typography>
                <Typography
                  variant="inherit"
                  className={`${classes.collaboratorStyle} subtle-text-small`}
                  display={"inline"}
                  onClick={this.handleShowCollaborators}
                >
                  {contact ??
                    get(evaluation, "evaluation.primary_contact", "N/A")}
                </Typography>

                <Typography
                  variant="inherit"
                  className={`${classes.collaboratorStyle} subtle-text-small`}
                  display={"inline"}
                  onClick={this.handleShowCollaborators}
                >
                  {collaboratorMessage}
                </Typography>
                <CollaboratorPopover
                  open={!!collaboratorsAnchorEl}
                  classes={{ paper: this.props.classes.paper }}
                  evaluation={effectiveEvaluation}
                  anchorEl={collaboratorsAnchorEl}
                  onClose={this.handleCloseCollaborators}
                />
              </Grid>
              <Hidden xsDown>
                <Button
                  className={"secondary-minimal-button-small no-print"}
                  variant={"text"}
                  style={{
                    width: 130,
                    height: 36,
                    marginLeft: 8,
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                  onClick={this.onEditOpen}
                  disableRipple={true}
                  disabled={!(evaluation && evaluation.evaluation)}
                >
                  <EditIcon style={{ marginRight: 4, fontSize: 18 }} />
                  <Typography
                    color="inherit"
                    variant="button"
                    id={"equipmentDetail.editCatalog.button"}
                  />
                </Button>
              </Hidden>
            </Grid>
          </Grid>
          <Grid>
            <Button
              variant={"text"}
              className={"secondary-button"}
              onClick={this.handleResendToIMS}
              style={{ height: 33, minWidth: 200 }}
            >
              <Typography
                color="inherit"
                variant="button"
                id={"equipmentList.resendCatalog.label"}
              />
            </Button>
          </Grid>
          <Grid item>
            <Feature feature={CUSTOMER_ENV}>
              {get(evaluation, "evaluation.workflow_status") ===
              "capture_in_progress" ? (
                <Button
                  className={"subtle-button"}
                  style={{ width: "auto" }}
                  onClick={this.handleSendtoPurpleWave}
                >
                  Send to PurpleWave
                </Button>
              ) : (
                <Typography id={"customerInventory.sentToPurpleWave.status"} />
              )}
            </Feature>
            <NotFeature feature={CUSTOMER_ENV}>
              <WorkflowStatusSelect
                classes={{ menuText: classes.menuText }}
                doHandleChanges
                evaluation={evaluation}
              />
            </NotFeature>
          </Grid>
          <Grid
            container
            item
            className={classes.containerStyle}
            wrap={"nowrap"}
            direction={"row"}
            alignItems={"center"}
          >
            <Grid item container style={{ width: "auto" }} direction={"row"}>
              <Hidden xsDown>
                <Typography
                  className={classNames(classes.savedLabel, {
                    [classes.savedTime]: !saving && savedTime,
                  })}
                  id={currentState}
                  values={{ savedTime }}
                />
              </Hidden>
              {allowExport && (
                <Hidden smDown>
                  <Button
                    variant={"text"}
                    className={"subtle-button"}
                    disabled={get(evaluation, "items.length", 0) <= 0}
                    onClick={this.handleExportClick}
                  >
                    <Typography
                      color="inherit"
                      variant="button"
                      id={"equipmentList.export.button"}
                    />
                    {isExporting && (
                      <CircularProgress
                        className={classes.spinnerMargin}
                        size={50}
                        thickness={2.5}
                      />
                    )}
                  </Button>
                  <Menu
                    id="status-menu-id"
                    style={{ userSelect: "none" }}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    PaperProps={{ style: { marginTop: 50 } }}
                    disableAutoFocusItem
                    open={!!anchorEl}
                    onClose={this.handleExportClose}
                  >
                    {isAssetDetailPath && [
                      <MenuItem
                        key={"dropboxSaverKey"}
                        name="dropboxSaver"
                        onClick={this.handleMenuItemClick("dropboxSaver")}
                        className={classes.menuItemStyle}
                      >
                        <Typography
                          className={classes.exportMenuText}
                          color="inherit"
                          variant="body1"
                          id={"equipmentList.exportDropboxSaver.menu"}
                        />
                      </MenuItem>,
                    ]}
                    {!isAssetDetailPath &&
                      (isAdminOnly() || isInternalTeam()) && [
                        <MenuItem
                          key={"submitToIms"}
                          name="submitToIms"
                          onClick={this.handleMenuItemClick("submitToIms")}
                          className={classes.menuItemStyle}
                        >
                          <Typography
                            className={classes.exportMenuText}
                            color="inherit"
                            variant="body1"
                            id={"equipmentList.submitToIms.menu"}
                          />
                        </MenuItem>,
                      ]}
                  </Menu>
                </Hidden>
              )}
            </Grid>
            <Hidden smDown>
              <Grid item>
                <NotificationsIcon
                  onClick={this.handleSubscribe}
                  className={classes.notificationStyle}
                  htmlColor={
                    isSubscribed
                      ? theme.palette.button.minimal.secondary
                      : theme.palette.button.minimal.disabled
                  }
                />
              </Grid>
            </Hidden>
            <Snackbar
              key={isSubscribed ? "subscribe" : "unsubscribe"}
              ContentProps={{
                classes: {
                  root: `${classes.subscribeSnackbarStyle} normal-default-text`,
                },
              }}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              open={this.state.openSubscribeSnackbar}
              autoHideDuration={CLOSE_DELAY_FOR_SUBSCRIBE}
              onClose={this.handleClose}
              message={
                <Grid
                  container
                  direction={"row"}
                  alignItems={"center"}
                  spacing={2}
                >
                  <Grid item>
                    <NotificationsIcon style={{ verticalAlign: "middle" }} />
                  </Grid>
                  <Grid item>
                    <Typography
                      hasLineBreaks
                      id={
                        isSubscribed
                          ? "subscribe.turnOn.text"
                          : "subscribe.turnOff.text"
                      }
                      color={"inherit"}
                      display={"inline"}
                    />
                  </Grid>
                </Grid>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={this.handleClose}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(
  withStyles(styles, { withTheme: true })(withRouter(EquipmentListHeader))
);
