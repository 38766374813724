import { withRouter, RouteComponentProps } from 'react-router-dom';
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ModalDialog from '../fhg/components/dialog/ModalDialog';
import ErrorSnackbar from '../components/eval/components/ErrorSnackbar';
import FormattedTextField from '../components/eval/components/FormattedTextField';
import Typography from '../fhg/components/Typography';
import { requestForServer } from '../Utils/ServerUtil';
import { EDIT_USER } from '../Constants';
import eventCapture from '../eventList';
import { posthogCapture } from '../PostHogFunction';


const styles = (theme: { spacing: (arg0: number) => any; }) => ({
  fullWidthInput: {
    marginTop: `${theme.spacing(0.5)}px !important`,
    height: 30,
    width: "100% !important",
  },
  mediumTopPad: {
    marginTop: theme.spacing(1.5),
  },
});

interface EditUserDialogProps extends RouteComponentProps {
  classes: any;
  open: boolean;
  onClose: () => void;
  onSubmit?: (user: any) => void;
  editUserSelected: string;
  userDetails: {
    name?: string;
    email?: string;
  };
}

const EditUserDialog: React.FC<EditUserDialogProps> = ({
  classes,
  open,
  onClose,
  onSubmit,
  editUserSelected,
  userDetails
}) => {
  const [name, setName] = useState(userDetails.name || '');
  const [email, setEmail] = useState(userDetails.email || '');
  const [isSaving, setIsSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'name') {
      setName(value);
    } else if (name === 'email') {
      setEmail(value);
    }
    setIsChanged(true);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    let error: any;
    let errorValues: any;

    if (isChanged && !isSaving) {
      setIsSaving(true);
      try {
        const user: { name?: string; email?: string } = {
          name: name || undefined,
          email: email || undefined
        };

        posthogCapture(eventCapture.SAVE_NEW_USER);

        const resultUser = await requestForServer(
          (EDIT_USER as any).format({ userId: editUserSelected }),
          "patch", 
          user
        );

        if (!resultUser.error) {
          posthogCapture(eventCapture.SAVE_NEW_USER);
          window.location.reload();
          
          if (onSubmit) {
            onSubmit(resultUser.data);
          }
        } else {
          error = resultUser.error;
          errorValues = resultUser.errorValues;
        }
      } catch (err) {
        error = err;
      }

      if (error) {
        setShowError(true);
        setMessage(errorValues?.message || error.message);
        setIsSaving(false);
      } else {
        setIsSaving(false);
        setIsChanged(false);
        onClose();
      }
    }
  };

  const handleErrorClose = () => {
    setShowError(false);
  };

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      titleKey="editUser.main.title"
      isSaving={isSaving}
      submitKey="editUser.submit.label"
      isForm
    >
      {showError && (
        <ErrorSnackbar
          open={showError}
          onClose={handleErrorClose}
          errorId="editUser.save.error"
          values={{ message }}
        />
      )}
      <Typography
        id="editUser.name.label"
        className={`${classes.mediumTopPad} label-text`}
      />
      <FormattedTextField
        {...({
          name: "name",
          autoFocus: true,
          fullWidth: true,
          value: name,
          onChange: handleChange,
          margin: "none",
          placeholder: userDetails.name || "Doug Jones",
          inputProps: { className: classes.fullWidthInput },
          disabled: isSaving
        } as any)}
      />
      <Typography
        id="editUser.email.label"
        className={`${classes.mediumTopPad} label-text`}
      />
      <FormattedTextField
      { ...({
        name: "email",
        type: "email",
        fullWidth: true,
        value: email,
        onChange: {handleChange},
        margin: "none",
        placeholder: userDetails.email || "doug@acme.com",
        inputProps: { className: classes.fullWidthInput },
        disabled : isSaving
      } as any)}
      />
    </ModalDialog>
  );
};

export default withRouter(withStyles(styles)(EditUserDialog));
