import { AlertCircle } from 'lucide-react';
import React from 'react';

const LoadFormPreview = () => (
    <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '400px',
        margin: '16px 20px',
        borderRadius: '8px',
        border: '1px solid #e5e7eb',
        backgroundColor: '#ffffff',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)'
      }}>
        <div style={{
          textAlign: 'center',
          padding: '32px'
        }}>
          <div style={{
            marginBottom: '24px'
          }}>
            <AlertCircle color='#4a287d' size={64} />
          </div>
          
          <h2 style={{
            marginBottom: '16px',
            fontSize: '24px',
            fontWeight: 600,
            color: '#1f2937'
          }}>
            Select a category
          </h2>
          
          <p style={{
            color: '#6b7280',
            maxWidth: '448px',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}>
            In order to load a form, please choose a category.
          </p>
        </div>
      </div>
);

export default LoadFormPreview;